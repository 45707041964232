import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faBooks, faCarBuilding, faComment, faHeart, faHomeHeart, faMailBulk, faMoneyCheckAlt, faMoneyCheckEditAlt, faPlusCircle, faSignIn, faSignOut, faSolarSystem, faStar, faTachometerAlt, faTags, faTicket, faUser, faUserTag, faUserTie, faUsers } from '@fortawesome/fontawesome-pro-duotone';
import { faBars } from '@fortawesome/fontawesome-pro-solid';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { switchMap, tap } from 'rxjs';
import { ProfileLiteView } from 'src/app/api/models';
import { AuthService } from 'src/app/shared/services/auth-service.service';
import { environment } from 'src/environments/environment';
import { ModeToggleComponent } from '../mode-toggle/mode-toggle.component';
import { SessionService } from '../services/session.service';
import { UpgradeComponent } from 'src/app/account/upgrade/upgrade.component';

@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
    imports: [RouterLink, FaIconComponent, BsDropdownModule, ModeToggleComponent, UpgradeComponent]
})
export class HeaderMenuComponent implements OnInit {
  private sessionService = inject(SessionService);
  private authService = inject(AuthService);
  private router = inject(Router);


  destroyRef = inject(DestroyRef);

  faPlusCircle = faPlusCircle;
  faSignin = faSignIn;
  faSignOut = faSignOut;
  faUsers = faUsers;
  faTicket = faTicket;
  faMoneyCheckEditAlt = faMoneyCheckEditAlt;

  faComments = faComment;
  faDashboard = faTachometerAlt;
  faHeart = faHeart;
  faBooks = faBooks;
  faMailBulk = faMailBulk;
  faStar = faStar;
  faHomeHeart = faHomeHeart
  faUser = faUser;
  faUserTie = faUserTie;
  faTags = faTags
  faCarBuilding = faCarBuilding;
  faSolarSystem = faSolarSystem;
  faUserTag = faUserTag;
  faMoneyCheckAlt = faMoneyCheckAlt;

  faBars = faBars;
  droppedDown: boolean = false;
  profile?: ProfileLiteView;
  environment = environment;

  show = false;
  theme = 'light';

  ngOnInit(): void {
    this.sessionService.myProfileLite$.pipe(
      tap(profile => this.profile = profile),
      switchMap(() => this.authService.isUserPro()),
      tap(res => this.isPro = res),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.router.events.pipe(
      tap(() => this.show = false),
      
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  get name(): string | null | undefined {
    if (this.profile) {
      return this.profile.name;
    }
    return 'My profile';
  }

  get isAuthenticated(): boolean {
    return this.sessionService.hasValidAccessToken();
  }

  get isLandlord(): boolean {
    return this.authService.isUserLandlord();
  }

  get isSales(): boolean {
    return this.authService.isUserSales();
  }

  get isAdmin(): boolean {
    return this.authService.isUserAdmin();
  }

  isPro = false;

  onLogout(e: Event) {
    e.preventDefault();
    this.sessionService.logout();
  }

  onGetStarted(e: Event) {
    this.router.navigate(['/add']);
  }

  onChangeTheme(theme: string) {
    this.theme = theme;
  }

}
