import { Component, HostListener, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { SessionService } from '../services/session.service';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';
import { SearchBoxComponent } from '../search-box/search-box.component';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [RouterLink, SearchBoxComponent, HeaderMenuComponent]
})
export class HeaderComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  scrolled: boolean = false;

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.scrolled = window.pageYOffset >= 100;
  }

  onAddressChanged(e: string) {
    this.router.navigate(['/search'], {
      queryParams: { q: e },
      relativeTo: this.route,
      skipLocationChange: false
    });
  }
}
