import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/fontawesome-pro-regular';
import { faSearch } from '@fortawesome/fontawesome-pro-solid';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { debounceTime, distinctUntilChanged, Observable, Observer, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { LocationResultView } from 'src/app/api/models';
import { GeoService } from 'src/app/api/services';
import { LocationService } from '../services';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    imports: [FaIconComponent, FormsModule, TypeaheadModule]
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  private geoService = inject(GeoService);
  private locationService = inject(LocationService);
  private router = inject(Router);

  @Input() location?:string;
  @Input() get placeholder():string { return false ? 'Enter an address, city, neighbourhood, or postal code.' : 'Find your new home...'; }
  @Input() autolocate = false;
  @Input() big = false;
  @Output() changed: EventEmitter<string> = new EventEmitter();

  icon = faSearch;
  faMapMarkerAlt = faMapMarkerAlt;
  faSearch = faSearch;

  end$ = new Subject<void>();
  locationsLoading = false;
  locationInput = new Subject<string>();
  text?: string;
  locations$?: Observable<string[]>;
  errorMessage?: string;

  ngOnInit(): void {
    this.locations$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.text);
    }).pipe(
      distinctUntilChanged(),
      debounceTime(300),
      tap(() => this.locationsLoading = true),
      switchMap((query: string) => query ? this.geoService.suggest({ text: query }) : of([])),
      tap(() => this.locationsLoading = false)
    );
  }

  ngOnDestroy(): void {
    this.end$.next();
    this.end$.complete();
  }

  onLocationChanged(e: TypeaheadMatch) {
    this.location = e.value;
    this.changed.emit(this.location);
  }

  onSearch() {
    this.router.navigate(['/search'], { queryParams: { q: this.text } });
  }

  async onFocus(e: Event) {
    if (this.autolocate && (!this.location || this.location === '')) {
      this.locationService.locateIp(this.locationCallback);
    }
  }

  locationCallback = async (item: Observable<LocationResultView>) => {

    item.pipe(
      takeUntil(this.end$),
      tap(result => {
        this.location = '';
        if (result.city) {
          this.location = result.city;
        }
        if (result.region) {
          if (this.location) {
            this.location = this.location + ', ';
          }
          this.location = this.location + result.region;
        }
        if (result.country) {
          if (this.location) {
            this.location = this.location + ', ';
          }
          this.location = this.location + result.country;
          this.changed.emit(this.location);
        }
      })
    ).subscribe();

  }

}
