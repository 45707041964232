import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-help-menu',
    templateUrl: './help-menu.component.html',
    styleUrls: ['./help-menu.component.scss'],
    imports: [RouterLink]
})
export class HelpMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
