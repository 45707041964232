<nav class="d-flex justify-content-end">
  @if (!isAuthenticated) {
  <a class="btn bg-bright-gradient me-2 d-sm-none d-md-block d-none d-sm-block text-nowrap ms-5"
    [routerLink]="['/add']">
    Get Started
  </a>
  }
  @if (isAuthenticated) {
  <a class="btn bg-bright-gradient me-2 d-sm-none d-md-block d-none d-sm-block text-nowrap ms-5"
    [routerLink]="['/add']">
    <fa-icon [icon]="faPlusCircle" class="me-1"></fa-icon>List Your Place
  </a>
  }

  <button class="btn btn-success rounded-circle ms-1" type="button" title="Show Menu" (click)="show=!show">
    <fa-icon [icon]="faBars"></fa-icon>
  </button>
  <div class="offcanvas offcanvas-end " [class.show]="show" tabindex="-1" id="offcanvasNavbar"
    aria-labelledby="offcanvasNavbarLabel">
    <div class="offcanvas-header">
      @if(theme!=='dark'){
      <img src="..\..\..\assets\images\rentability-logo.png" width="166" height="35" title="Logo"
        class="d-sm-none d-md-block d-none d-sm-block me-5" />
      } @else {
      <img src="..\..\..\assets\images\rentability-logo2.png" width="166" height="35" title="Logo"
        class="d-sm-none d-md-block d-none d-sm-block me-5" />
      }
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
        (click)="show=false"></button>
    </div>
    <div class="offcanvas-body">
      <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
        @if (!isAuthenticated) {
        <li class="nav-item text-nowrap nav-item fw-bold mb-3">
          <a href="#" class="btn btn-primary link-underline link-underline-opacity-0 w-50 me-2"
            [routerLink]="['/register']">Sign Up</a>
          <a href="#" class="btn bg-bright-gradient link-underline link-underline-opacity-0 w-50"
            [routerLink]="['/login']">Login</a>
        </li>
        }
        @if (isAuthenticated) {
        <li class="nav-item">
          <a class="nav-link fw-bold link-underline link-underline-opacity-0" href="/me" [routerLink]="['/me']">
            <img title="Profile Photo" src="{{environment.api}}/api/file/getEntityImage/{{profile?.id}}"
              class="rounded-circle me-1 profile-image" width="35" height="35" />{{profile?.name ?? 'Your'}}
            Profile</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/dashboard" [routerLink]="['/dashboard']"><fa-icon [icon]="faDashboard"
              class="me-1"></fa-icon>Dashboard</a>
        </li>
        @if(isLandlord){
        <li class="nav-item">
          <a class="nav-link" href="/listings" [routerLink]="['/listings']"><fa-icon [icon]="faCarBuilding"
              class="me-1"></fa-icon>Listings</a>
        </li>
        }
        <li class="nav-item">
          <a class="nav-link" href="/favorites" [routerLink]="['/favorites']"><fa-icon [icon]="faHeart"
              class="me-1"></fa-icon>Favourites</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/applications" [routerLink]="['/applications']"><fa-icon [icon]="faMailBulk"
              class="me-1"></fa-icon>Applications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/messages" [routerLink]="['/messages']"><fa-icon [icon]="faComments"
              class="me-1"></fa-icon>Messages</a>
        </li>
        @if(isPro){
        <li class="nav-item">
          <a class="nav-link" href="/payments" [routerLink]="['/payments']"><fa-icon [icon]="faMoneyCheckEditAlt"
              class="me-1"></fa-icon>Your
            Payments</a>
        </li>}

        @if(isSales || isAdmin){
        <li class="nav-item">
          <a class="nav-link" href="/refer" [routerLink]="['/refer']"><fa-icon [icon]="faMailBulk"
              class="me-1"></fa-icon>Refer a Friend</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/coupons" [routerLink]="['/coupons']"><fa-icon [icon]="faTicket"
              class="me-1"></fa-icon>Coupons</a>
        </li>

        }

        <hr class="nav-divider">

        @if(isAdmin){
        <li class="nav-item">
          <a class="nav-link" href="/users" [routerLink]="['/users']"><fa-icon [icon]="faUsers"
              class="me-1"></fa-icon>Users <span class="badge bg-danger">Admin</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/roles" [routerLink]="['/roles']"><fa-icon [icon]="faUserTag"
              class="me-1"></fa-icon>Roles <span class="badge bg-danger">Admin</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/plans" [routerLink]="['/plans']"><fa-icon [icon]="faSolarSystem"
              class="me-1"></fa-icon>Plans <span class="badge bg-danger">Admin</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/subscriptions" [routerLink]="['/subscriptions']"><fa-icon [icon]="faMoneyCheckAlt"
              class="me-1"></fa-icon>Subscriptions <span class="badge bg-danger">Admin</span></a>
        </li>
        <hr class="nav-divider">
        }
        }
        <li class="nav-item">
          <a class="nav-link btn bg-bright-gradient d-sm-block d-md-none text-nowrap" [routerLink]="['/add']">
            <fa-icon [icon]="faPlusCircle" class="me-1"></fa-icon>List Your Place
          </a>
        </li>
        <hr class="nav-divider d-sm-block d-md-none">
        <li class="nav-item">
          <a class="nav-link" href="/resources" [routerLink]="['/resources']">
            <fa-icon [icon]="faBooks" class="me-1"></fa-icon>Resources</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/pricing" [routerLink]="['/pricing']">
            <fa-icon [icon]="faTags" class="me-1"></fa-icon>Pricing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/home/tenants" [routerLink]="['/home','tenants']">
            <fa-icon [icon]="faUser" class="me-1"></fa-icon>Tenants</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/home/landlords" [routerLink]="['/home','landlords']">
            <fa-icon [icon]="faUserTie" class="me-1"></fa-icon>Landlords</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/home/ratings" [routerLink]="['/home','ratings']"><fa-icon [icon]="faStar"
              class="me-1"></fa-icon>Ratings</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="['/home','end-homelessness']">
            <fa-icon [icon]="faHomeHeart" class="me-1"></fa-icon>End Homelessness</a>
        </li>

        @if(isAuthenticated){
        <hr class="nav-divider">
        <li class="nav-item ">
          <a class="nav-link text-right" href="#" (click)="onLogout($event)">
            <fa-icon [icon]="faSignOut" class="me-1"></fa-icon>Logout
          </a>
        </li>

        <app-upgrade [isMinimized]="true"></app-upgrade>
        }
      </ul>
      <app-mode-toggle (changeTheme)="onChangeTheme($event)"></app-mode-toggle>
    </div>
  </div>


</nav>